// audio files
import thevibe from './thevibe.mp3';
import amin from './amin.mp3';
import warden from './warden.mp3';
import rise from './rise.mp3';
import justmakebeats from './justmakebeats.mp3';
import blip from './blip.mp3';
import gauntlet1 from './gauntlet1.mp3';
import myloseyourself from './myloseyourself.mp3';
import thanksmom from './thanksmom.mp3';
import doowopmurda from './doowopmurda.mp3';
import brooklyn9 from './brooklyn9.mp3';

import hhtlp1 from './hiphopthelp/01_Track_1.mp3';
import hhtlp2 from './hiphopthelp/02_Track_2.mp3';
import hhtlp3 from './hiphopthelp/03_Track_3.mp3';
import hhtlp4 from './hiphopthelp/04_Track_4.mp3';
import hhtlp5 from './hiphopthelp/05_Track_5.mp3';
import hhtlp6 from './hiphopthelp/06_Track_6.mp3';
import hhtlp7 from './hiphopthelp/07_Track_7.mp3';
import hhtlp8 from './hiphopthelp/08_Track_8.mp3';
import hhtlp9 from './hiphopthelp/09_Track_9.mp3';
import hhtlp10 from './hiphopthelp/10_Track_10.mp3';
import hhtlp11 from './hiphopthelp/11_Track_11.mp3';
import hhtlp12 from './hiphopthelp/12_Track_12.mp3';
import hhtlp13 from './hiphopthelp/13_Track_13.mp3';
import hhtlp14 from './hiphopthelp/14_Track_14.mp3';
import hhtlp15 from './hiphopthelp/15_Track_15.mp3';
import hhtlp16 from './hiphopthelp/16_Track_16.mp3';
import hhtlp17 from './hiphopthelp/17_Track_17.mp3';
import hhtlp18 from './hiphopthelp/18_Track_18.mp3';
import hhtlp19 from './hiphopthelp/19_Track_19.mp3';
import hhtlp20 from './hiphopthelp/20_Track_20.mp3';
import hhtlp21 from './hiphopthelp/21_Track_21.mp3';
import hhtlp22 from './hiphopthelp/22_Track_22.mp3';


import sj1 from './sophomorejinx/1_bigbrother.mp3';
import sj2 from './sophomorejinx/2_pressure_and_pain.mp3';
import sj3 from './sophomorejinx/3_moehautshit.mp3';
import sj4 from './sophomorejinx/4_keep_knockin.mp3';
import sj5 from './sophomorejinx/5_untitled.mp3';
import sj6 from './sophomorejinx/6_destruct_the_house.mp3';
import sj7 from './sophomorejinx/7_indeed_we_might_be.mp3';
import sj8 from './sophomorejinx/8_bump_bump.mp3';
import sj9 from './sophomorejinx/9_hell_no.mp3';
import sj10 from './sophomorejinx/10_the_choice_is_yours.mp3';
import sj11 from './sophomorejinx/11_keep_it_movin.mp3';
import sj12 from './sophomorejinx/12_just_relax.mp3';
import sj13 from './sophomorejinx/13_my_people.mp3';
import sj14 from './sophomorejinx/14_nothing_to_lose.mp3';
import sj15 from './sophomorejinx/15_instr1.mp3';
import sj16 from './sophomorejinx/16_isntr2.mp3';
import sj17 from './sophomorejinx/17_instr3.mp3';
import sj18 from './sophomorejinx/18_instr4.mp3';


import iamhiphop from './grandskeme/iamhiphop.mp3';
import chillyrhodes from './grandskeme/chillyrhodes.mp3';
import imbad from './grandskeme/imbad.mp3';
import imnmc from './grandskeme/imnmc.mp3';
import paternallyyours from './grandskeme/paternallyyours.mp3';
import maurice from './grandskeme/maurice.mp3';


// audio thumbnails
import triplenickel from './whiskeybravo_triplenickel.jpg';
import hades from './hades.png'
import hhtlp from './hiphopthelp.jpg'
import grandskeme from './grandskeme.png'
import sj from './sophomorejinx/sj.gif'

export const tracks = [
  {
    title: 'The Vibe',
    src: thevibe,
    author: 'Whiskey Bravo f/ M. Basinski',
    thumbnail: triplenickel,
    playlist: 'instrumentals',
  },
  {
    title: 'Amin',
    src: amin,
    author: 'Whiskey Bravo',
    thumbnail: triplenickel,
    playlist: 'instrumentals',
  },
  {
    title: 'The Warden',
    src: warden,
    author: 'Whiskey Bravo',
    thumbnail: triplenickel,
    playlist: 'instrumentals',
  },
  {
    title: 'Rise (WIP)',
    src: rise,
    author: 'Whiskey Bravo, M. Basinski',
    thumbnail: triplenickel,
    playlist: 'instrumentals',
  },
  {
    title: 'Gondor',
    src: justmakebeats,
    author: 'Whiskey Bravo',
    thumbnail: hades,
    playlist: 'beats',
  },
  {
    title: 'Blip',
    src: blip,
    author: 'Whiskey Bravo',
    thumbnail: hades,
    playlist: 'beats',
  },
  {
    title: 'Gauntlet1',
    src: gauntlet1,
    author: 'Whiskey Bravo',
    thumbnail: hades,
    playlist: 'beats',
  },
  {
    title: 'mYlOsEyoURsElf',
    src: myloseyourself,
    author: 'Whiskey Bravo',
    thumbnail: hades,
    playlist: 'beats',
  },
  {
    title: 'Thanks Mom',
    src: thanksmom,
    author: 'Whiskey Bravo',
    thumbnail: hades,
    playlist: 'beats',
  },
  {
    title: 'DooWopMurda',
    src: doowopmurda,
    author: 'Whiskey Bravo',
    thumbnail: hades,
    playlist: 'beats',
  },
  {
    title: 'Intro',
    src: hhtlp1,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: '\'Round Midnight (Monk Mix)',
    src: hhtlp2,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'I\'m Every Emcee',
    src: hhtlp3,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Act Like A Woman',
    src: hhtlp4,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Beatboxin\' in the Street (Break)',
    src: hhtlp5,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Keep it Complicated',
    src: hhtlp6,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Who in Their Right Mind',
    src: hhtlp7,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Do You Really ?? (UnderH20 Mix)',
    src: hhtlp8,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'JzHip-Hop',
    src: hhtlp9,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Cipher Break #1',
    src: hhtlp10,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'I Don\'t Kick Rhymes (Side B Intro)',
    src: hhtlp11,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Sacred',
    src: hhtlp12,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Don\'ts/Dos',
    src: hhtlp13,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Life Can Get',
    src: hhtlp14,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'ThreeSix0s (Instrumental Break)',
    src: hhtlp15,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'God Bless Ya Life',
    src: hhtlp16,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'The Session Before (Instrumental Break)',
    src: hhtlp17,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Bestfriend',
    src: hhtlp18,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Complex Concepts (Simple Ideas)',
    src: hhtlp19,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Cipher Break #2',
    src: hhtlp20,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: '\'Round Midnight (San Jose Mix)',
    src: hhtlp21,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'Outro (Thankyous)',
    src: hhtlp22,
    author: 'the eClecTic (1999)',
    thumbnail: hhtlp,
    playlist: 'theeclectichhtlp',
  },
  {
    title: 'I Am Hip-Hop',
    src: iamhiphop,
    author: 'Grand Skeme',
    thumbnail: grandskeme,
    playlist: 'grandskeme',
  },
  {
    title: 'Chilly Rhodes',
    src: chillyrhodes,
    author: 'Grand Skeme',
    thumbnail: grandskeme,
    playlist: 'grandskeme',
  },
  {
    title: 'IMNMC',
    src: imnmc,
    author: 'Grand Skeme',
    thumbnail: grandskeme,
    playlist: 'grandskeme',
  },
  {
    title: 'Maurice',
    src: maurice,
    author: 'Grand Skeme',
    thumbnail: grandskeme,
    playlist: 'grandskeme',
  },
  {
    title: 'I\'m Bad',
    src: imbad,
    author: 'Grand Skeme',
    thumbnail: grandskeme,
    playlist: 'grandskeme',
  },
  {
    title: 'Paternally Yours',
    src: paternallyyours,
    author: 'Grand Skeme',
    thumbnail: grandskeme,
    playlist: 'grandskeme',
  },
  {
    title: 'Big Brother',
    src: sj1,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Pressure and Pain',
    src: sj2,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Moehautsheeyat',
    src: sj3,
    author: 'the eClecTic f/ Grand Skeme (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Keep Knockin',
    src: sj4,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Untitled',
    src: sj5,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Destruct the House',
    src: sj6,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Indeed, We Might Be',
    src: sj7,
    author: 'the eClecTic f/ Shamako Noble (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Bump Bump',
    src: sj8,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Hell No',
    src: sj9,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'The Choice Is Yours (Revisted, Again)',
    src: sj10,
    author: 'the eClecTic f/ Persia (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Keep It Movin',
    src: sj11,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Just Relax',
    src: sj12,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'My People',
    src: sj13,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Nothing To Lose',
    src: sj14,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Instrumental 1',
    src: sj15,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Instrumental 2',
    src: sj16,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Instrumental 3',
    src: sj17,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Instrumental 4',
    src: sj18,
    author: 'the eClecTic (2001)',
    thumbnail: sj,
    playlist: 'sophomorejinx',
  },
  {
    title: 'Brooklyn9',
    src: brooklyn9,
    author: 'Whiskey Bravo',
    thumbnail: hades,
    playlist: 'beats',
  },
];
