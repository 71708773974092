import { useRef, useState, useEffect } from 'react';
import { tracks } from '../data/tracks';

// import components
import DisplayTrack from './DisplayTrack';
import Controls from './Controls';
import ProgressBar from './ProgressBar';

const AudioPlayer = () => {
  
  // PlayList
  const getInitialState = () => {
    const value = "instrumentals";
    return value;
  };

  // states
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playList, setPlayList] = useState(getInitialState);

  // reference
  const audioRef = useRef();
  const progressBarRef = useRef();
  var playtracks = tracks.filter(track => track.playlist === playList);

  const onOptionChangeHandler = (e) => {    
    setPlayList(e.target.value);    
    setPlayList((playList) => {
      playtracks = tracks.filter(track => track.playlist === playList);
      setCurrentTrack(playtracks[0]);  
      setCurrentTrack((currentTrack) => {
        setTrackIndex(0);
        setTrackIndex((trackIndex) => {          
          return trackIndex;
        });
        return currentTrack;
      });
      return playList;
    });
  };

  useEffect(() => {

    
  }, []);

  const handleNext = () => {
    if (trackIndex >= playtracks.length - 1) {
      setTrackIndex(0);
      setCurrentTrack(playtracks[0]);
    } else {
      setTrackIndex((prev) => prev + 1);
      setCurrentTrack(playtracks[trackIndex + 1]);
    }
  };

  const handlePrevious = () => {
    if (trackIndex === 0) {
      let lastTrackIndex = playtracks.length - 1;
      setTrackIndex(lastTrackIndex);
      setCurrentTrack(playtracks[lastTrackIndex]);
    } else {
      setTrackIndex((prev) => prev - 1);
      setCurrentTrack(playtracks[trackIndex - 1]);
    }
  };

  return (
    <>
      
      <div className="audio-player">
        <div className="inner">
          <div className="play-list">             
            <select onChange={onOptionChangeHandler}>
                <option defaultValue value="instrumentals">Whiskey Bravo</option>
                <option value="beats">Dubb Beats</option>
                <option value="grandskeme">Grand Skeme</option>
                <option value="theeclectichhtlp">the eClecTic | Hip-Hop, the LP (1999)</option>
                <option value="sophomorejinx">the eClecTic | Sophomore Jinx (2001)</option>
                
            </select>
          </div>
          <DisplayTrack
            {...{
              currentTrack,
              audioRef,
              setDuration,
              progressBarRef,
              handleNext,
            }}
          />
          <ProgressBar
            {...{ progressBarRef, audioRef, timeProgress, duration }}
          />
          <Controls
            {...{
              audioRef,
              progressBarRef,
              duration,
              setTimeProgress,
              handlePrevious,
              trackIndex,
              setTrackIndex,
              setCurrentTrack,
              handleNext,
            }}
          />
          
        </div>
      </div>
    </>
  );
};
export default AudioPlayer;
